@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: Neon;
  src: url("fonts/NeonSans.ttf") format("opentype");
}
@font-face {
  font-family: Alegreya-Italic;
  src: url("fonts/Alegreya-Italic-VariableFont_wght.ttf") format("opentype");
}
@font-face {
  font-family: Alegreya;
  src: url("fonts/Alegreya-VariableFont_wght.ttf") format("opentype");
}
body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-container svg {
  font-size: 75px;
  border-radius: 10px;
 
}


.icon:hover{
  color:#00ccff;
}
